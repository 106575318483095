import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  item: {
    [theme.breakpoints.up('xl')]: {
      width: `calc(20% - 12px)`,
      margin: '12px 0 0 12px',
    },
    [theme.breakpoints.down('lg')]: {
      width: `calc(25% - 12px)`,
      margin: '12px 0 0 12px',
    },
    [theme.breakpoints.down('md')]: {
      width: `calc(33.33% - 12px)`,
      margin: '12px 0 0 12px',
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(50% - 12px)`,
      margin: '12px 0 0 12px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

const CustomGridItem = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.item}>{children}</Box>;
};

export default CustomGridItem;

import React, { useState, useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import CardImageTitleDescription from '../../components/card-image-title-description/card-image-title-description';
import { Grid, Box, Typography, TextField } from '@material-ui/core';
import { useDebounce } from 'use-debounce';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import CustomGrid from '../../components/custom-grid/custom-grid';
import CustomGridItem from '../../components/custom-grid/custom-grid-item';
import moment from 'moment';

const NewsIndexPage = ({ pageContext: { newsArray, newsYears } }) => {
  const [years, setYears] = useState(newsYears.sort((a, b) => b - a));
  const [hasResult, setHasResult] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredNews, setFilteredNews] = useState(newsArray);
  const [value] = useDebounce(searchText, 1000);
  const [news] = useState(newsArray);

  useEffect(() => {
    let _allYears = years;
    if (value.length > 0) {
      let _filteredNews = [];
      let _filteredYears = [];

      newsArray.forEach((_news) => {
        if (
          _news.title.toLowerCase().includes(value.toLocaleLowerCase()) ||
          _news.article.toLowerCase().includes(value.toLocaleLowerCase())
        ) {
          _filteredNews.push(_news);
          if (!_filteredYears.includes(_news.year)) {
            _filteredYears.push(_news.year);
          }
        }
      });

      if (_filteredNews.length > 0) {
        setHasResult(true);
        setFilteredNews(_filteredNews);
        setYears(_filteredYears);
      } else {
        setYears([]);
        setHasResult(false);
      }
    } else {
      setHasResult(true);
      setFilteredNews(news);
      setYears(_allYears);
    }

    return () => {};
  }, [value]);

  return (
    <Layout>
      <SEO
        lang='en'
        title=' News | Global Manufacturing & Industrialisation Summit'
        keywords='manufacturing & Innovation summit news, Global manufacturing innovations news 2022 , GMIS Summit News Conference, Dubai Industrial & Innovation summit news, GMIS Summit News & Conference in Dubai, Best manufacturing innovations news 2022'
        description='Join us for the manufacturing, digitalization, and innovation summit news & conference in Dubai. Improve your knowledge with the best industry speakers. Visit GMIS today.'
      />
      <SectionContainerLayoutWithFilter title='NEWS' isViewAll isDivider filters={years} baseLink='/news'>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8} md={6}>
            <TextField
              placeholder='Search news'
              label='Search news'
              color='secondary'
              variant='outlined'
              fullWidth
              onChange={(e) => setSearchText(e.target.value)}
            />
          </Grid>
          {hasResult ? (
            years.map((_year, yearIndex) => (
              <Grid key={yearIndex} item xs={12}>
                <Typography gutterBottom variant='h6' className='text-uppsercase'>
                  <Box my={3}>{_year}</Box>
                </Typography>
                <CustomGrid>
                  {filteredNews.map((_news, newsIndex) =>
                    _news.year === _year ? (
                      <CustomGridItem key={newsIndex}>
                        <CardImageTitleDescription
                          isImgTag
                          image={_news.file.code}
                          title={moment(_news.date).format('DD MMM YYYY')}
                          body={_news.title}
                          readMore={`/news/${_year}/${_news.urlSlug}`}
                          disabelRippleEffect
                          lines={4}
                        />
                      </CustomGridItem>
                    ) : null
                  )}
                </CustomGrid>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Box m={8} p={{ xs: 0, sm: 8 }} fullWidth textAlign='center'>
                <Typography color='textSecondary' variant='button'>
                  No News Found
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </SectionContainerLayoutWithFilter>
    </Layout>
  );
};

export default NewsIndexPage;
